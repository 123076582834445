<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 10px"
      title="调拨库位列表"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    >
      <a-row type="flex">
      </a-row>
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="原库位">
              <a-select
                allow-clear
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                show-search
                v-model="queryParam.from_cargo_space_id"
                placeholder="库位名称"
                :default-active-first-option="false"
              >
                <a-select-option v-for="d in from_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="目标库位">
              <a-select
                allow-clear
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                show-search
                v-model="queryParam.to_cargo_space_id"
                placeholder="库位名称"
                :default-active-first-option="false"
              >
                <a-select-option v-for="d in to_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-if="this.mdl.status === 1">新建</a-button>
      <a-button type="primary" icon="export" @click="handleExport" v-if="false">导出</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-if="mdl.status === 1"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/trans_inventory_detail/modules/CreateForm'
import {
  transfer_inventory_detail_delete,
  transfer_inventory_detail_export,
  transfer_inventory_detail_list, wms_transfer_inventory_detail_create
} from '@/api/c_wms_trans_inventory'
import { Base64 } from 'js-base64'

const { cargo_space_list } = require('@/api/c_wms_cargo_space')

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      from_wms_cargo_space: [],
      to_wms_cargo_space: [],
      // 创建窗口控制
      visible: false,
      mdl: {},
      dataSource_wms_cargo_space: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '原库位',
          ellipsis: true,
          width: 150,
          dataIndex: 'from_cargo_space'
        },
        {
          width: 150,
          ellipsis: true,
          title: '目标库位',
          dataIndex: 'to_cargo_space'
        },
        {
          width: 180,
          title: '货品名称',
          dataIndex: 'goods_name',
          ellipsis: true
        },
        {
          width: 180,
          title: '货品条形码',
          dataIndex: 'goods_purchase',
          ellipsis: true
        },
        {
          width: 100,
          title: '数量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return transfer_inventory_detail_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.mdl = JSON.parse(Base64.decode(this.$route.query.record || ''))
    this.queryParam.transfer_id = this.mdl.id
    this.handleWmsFromCargoSpaceSearch(this.mdl.from_warehouse_id)
    this.handleWmsToCargoSpaceSearch(this.mdl.to_warehouse_id)
  },
  methods: {
    subTitle () {
      return this.mdl.from_warehouse + ' -> ' + this.mdl.to_warehouse
    },
    handleAdd () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDelet (record) {
      console.log(record.id)
      transfer_inventory_detail_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.transfer_id = this.mdl.id
          wms_transfer_inventory_detail_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleWmsFromCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.from_wms_cargo_space = result
      })
    },
    handleWmsToCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.to_wms_cargo_space = result
      })
    },
    handleExport () {
      transfer_inventory_detail_export({ transfer_id: this.mdl.id }).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `调拨单.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>
