<template>
  <a-modal
    title="新建调拨单"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="原库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                v-decorator="[
                  'from_cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in from_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="货品">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                v-decorator="[
                  'goods_purchase_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="货品名称"
                :default-active-first-option="false"
                @search="handleWmsGoodsSearch"
                @change="handleWmsGoodsChange"
              >
                <a-select-option v-for="d in dataSource_wms_goods" :key="d.goods_purchase_id" :tt="d.goods_purchase_barcode">
                  {{ d.goods_name }}（{{ d.goods_purchase_barcode }}）({{ d.goods_purchase_batch }})
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="目标库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                v-decorator="[
                  'to_cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
              >
                <a-select-option v-for="d in to_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="调拨数量">
              <a-input-number :min="0" :max="transfer_max_count" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <span style="padding-left: 10px" v-if="true">最大值：{{ transfer_max_count }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'

// 表单字段
const fields = ['from_cargo_space_id', 'goods_purchase_id', 'count', 'from_cargo_space_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      form: this.$form.createForm(this),
      from_wms_cargo_space: [],
      to_wms_cargo_space: [],
      dataSource_wms_goods: [],
      transfer_max_count: 0
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.handleWmsFromCargoSpaceSearch(this.model.from_warehouse_id)
    this.handleWmsToCargoSpaceSearch(this.model.to_warehouse_id)
  },
  methods: {
    handleWmsFromCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.from_wms_cargo_space = result
      })
    },
    handleWmsToCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.to_wms_cargo_space = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      const objneo = this.from_wms_cargo_space.find((item) => {
        return item.id === value
      })
      const obj = Object.assign({}, objneo)
      this.form.setFieldsValue({ goods_purchase_id: undefined })
      this.handleWmsGoodsSearch(obj)
    },
    handleWmsGoodsSearch (value) {
      wms_warehouse_inventory_list({ warehouse_id: this.model.from_warehouse_id, cargo_space_id: value.id }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_goods = result
        console.log(this.dataSource_wms_goods)
      })
    },
    handleWmsGoodsChange (value) {
      const objneo = this.dataSource_wms_goods.find((item) => {
        return item.goods_purchase_id === value
      })
      const obj = Object.assign({}, objneo)
      this.transfer_max_count = Number(obj.available_count)
    }
  }
}
</script>
