var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建调拨单","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"原库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'from_cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'from_cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"placeholder":"库位名称","default-active-first-option":false},on:{"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.from_wms_cargo_space),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"货品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'goods_purchase_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'goods_purchase_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"placeholder":"货品名称","default-active-first-option":false},on:{"search":_vm.handleWmsGoodsSearch,"change":_vm.handleWmsGoodsChange}},_vm._l((_vm.dataSource_wms_goods),function(d){return _c('a-select-option',{key:d.goods_purchase_id,attrs:{"tt":d.goods_purchase_barcode}},[_vm._v(" "+_vm._s(d.goods_name)+"（"+_vm._s(d.goods_purchase_barcode)+"）("+_vm._s(d.goods_purchase_batch)+") ")])}),1)],1),_c('a-form-item',{attrs:{"label":"目标库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'to_cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'to_cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"placeholder":"库位名称","default-active-first-option":false}},_vm._l((_vm.to_wms_cargo_space),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"调拨数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"min":0,"max":_vm.transfer_max_count}}),(true)?_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v("最大值："+_vm._s(_vm.transfer_max_count))]):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }